<template>
  <div style="position: relative; height: calc(100vh - 90px);">
    <NGrid :x-gap="15" :cols="24">
      <NGi :span="manualAdding ? 14 : 24">
        <NScrollbar ref="scrollbarRef" style="height: calc(100vh - 170px); max-height: calc(100vh - 170px);">
          <NCard
            title="试卷大纲"
            size="small"
            :segmented="{ content: 'hard' }"
          >
            <NForm
              ref="formRef"
              label-placement="left"
              :label-width="88"
              require-mark-placement="left"
              :model="formValue"
              :rules="formRules"
            >
              <NFormItem label="标题：" path="title">
                <NInput
                  style="width: 300px;"
                  maxlength="50"
                  show-count
                  clearable
                  v-model:value="formValue.title"
                />
              </NFormItem>
              <NFormItem label="标签：" path="label_ids">
                <ItemAddLabel
                  ref="itemAddLabelRef"
                  :label-type="resourceEnum.PAPER_RESOURCE_TYPE"
                  @list-update="handleLabelChange"
                />
              </NFormItem>
            </NForm>
            <div style="margin-top: 15px; border-top: 1px dashed #ddd;">
              <ExerciseItem
                v-for="(item, index) in formValue.paper_exercises"
                :key="item.id"
                :item-index="index"
                :exercise-data="item"
                permit-edit
                @add-click="handleAfterAdd"
                @exercise-del="handleExerciseDel"
                @exercise-pre="handleSeqPre"
                @exercise-next="handleSeqNext"
                @exercise-top="handleSeqTop"
                @exercise-bottom="handleSeqBottom"
                @exercise-copy="handleCopyExercise"
                @exercise-edit="handleEditExercise"
              />
            </div>
            <NSpace justify="center" style="margin-top: 8px;">
              <!--
              <NDropdown
                width="trigger"
                trigger="click"
                :options="batAddBtnOptions"
                @select="handleClickBatAdd"
              >
                <NButton size="large" style="width: 120px;" icon-placement="right">
                  <template #icon>
                    <NIcon>
                      <ChevronDownOutline />
                    </NIcon>
                  </template>
                  批量添加
                </NButton>
              </NDropdown>
              -->
              <NButton size="large" @click="handleClickBatAdd('localUpdate')">批量添加题目</NButton>
              <NButton size="large" type="primary" style="width: 120px;" @click="handleClickManualAdd">
                <!-- 手动 -->添加题目
              </NButton>
            </NSpace>
          </NCard>
        </NScrollbar>
      </NGi>
      <NGi :span="manualAdding ? 10 : 0">
        <NScrollbar style="height: calc(100vh - 170px); max-height: calc(100vh - 170px);">
          <AddExercise
            ref="addExerciseRef"
            use-for="forData"
            :is-edit="exerciseEditing"
            :is-test-paper="2"
            :res-req-params-map="exerciseResReqParamsMap"
            @back-click="handleCancelExercise"
            @save-click="handleConfirmExercise"
          />
        </NScrollbar>
      </NGi>
    </NGrid>

    <NCard size="small" style="position: absolute; bottom: 0; width: 100%;">
      <NSpace>
        <NButton size="large" @click="handleBack">返回</NButton>
        <NButton size="large" type="primary" style="width: 120px;" @click="handleDone">完成</NButton>
      </NSpace>
      <div style="position: absolute; left: 50%; top: 12px; transform: translate(-50%,0); line-height: 40px; font-size: 16px;">
        当前卷面总分值：
        <span :style="{ color: themeSettings.appThemeColor }">{{ totalScore }} 分</span>
      </div>
    </NCard>

    <AddExercisesModal
      :is-test-paper-call="isTestPaperCall"
      ref="addExercisesModalRef"
      @exercise-id-add="handleAddExerciseIds" 
    />
    <BatImportExercisesModal
      ref="batImportRef"
      bat-import-type="forData"
      :bat-import-fn="batImportExercisesForData"
      @list-update="addExercises({ where: 'last', exercises: $event })"
    />

    <!-- allArrIDS:所有选择的ID exerciseIdAll:此次选择的ID -->
    <RandomAdd ref="randomVolume"
    @exercise-id-add="handleAddExerciseIds"
    :exercise-id-all="allArrIDS"
    @exercise-id-all="exerciseIdAll"
    />
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive, computed, nextTick } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useMessage } from 'naive-ui';
  import { ChevronDownOutline } from '@vicons/ionicons5';

  import PageLoading from '@/components/PageLoading/index.vue';
  import ItemAddLabel from '@/components/ItemAddLabel/ItemAddLabel.vue';
  import AddExercisesModal from '@/components/AddExercisesModal/AddExercisesModal.vue';
  import ExerciseItem from '@/components/ExerciseItem/ExerciseItem.vue';
  import BatImportExercisesModal from '@/components/BatImportExercisesModal/BatImportExercisesModal.vue';
  import RandomAdd from '@/components/RandomAdd/index.vue';
  import AddExercise from '@/components/AddExercise/AddExercise.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { composePaperWayEnum } from '@/enumerators/compose-paper-way-map.js';
  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  import { questionTypeEnum } from '@/enumerators/question-type-map.js';
  import themeSettings from '@/settings/theme-settings.js';
  import { toRaw } from '@vue/reactivity'
  
  import {
    getExercises,
    batImportExercisesForData,
    createPaperFromExercises,
    getPaperDetail,
    editPaperFromExercises
  } from '@/api/paper.js';

  const { SUCCESS } = resStatusEnum;
  const router = useRouter();
  const route = useRoute();
  const message = useMessage();
  const loading = ref(false);
  
  const paperId = route.query.id;
  const isEdit = !!paperId;

  const resReqDataMap = {
    title: {
      reqKey: 'Paper[title]',
      default: '',
      rule: {
        required: true,
        message: '必填',
        trigger: 'blur'
      }
    },
    type: {
      reqKey: 'Paper[type]',
      default: composePaperWayEnum.MANUAL
    },
    label_ids: {
      reqKey: 'Paper[label]',
      default: [],
      rule: {
        key: 'label_ids',
        type: 'array',
        required: true,
        message: '必选'
      }
    }    
  };
  const exerciseObjKeys = [
    'title',
    'type',
    'score',
    'ques_select',
    'ques_select_num',
    'ques_answer',
    'ques_analys',
    'upload_list',
    'id'
  ];
  if (isEdit) {
    Object.assign(resReqDataMap, {
      id: {
        reqKey: 'Paper[id]',
        default: paperId
      },
      paper_exercises: {
        reqKey: 'Paper[add_paper_manual]',
        default: [
          /*
          {
            title: xx,
            type: xx,
            score: xx,
            ques_select: ['A.xx', 'B.xx'],
            ques_select_num: xx,
            ques_answer: 'A B',
            ques_analys: xx
          }
          */
        ]
      },
      deleteExerciseIds: {
        reqKey: 'Paper[delete_paper_manual]',
        default: []
      }
    });
  } else {
    Object.assign(resReqDataMap, {
      paper_exercises: {
        reqKey: 'Paper[mamuals]',
        default: [
          /*
          {
            title: xx,
            type: xx,
            score: xx,
            ques_select: ['A.xx', 'B.xx'],
            ques_select_num: xx,
            ques_answer: 'A B',
            ques_analys: xx
          }
          */
        ]
      }
    });
  }
  // 初始化时重置随机已选ID集
  sessionStorage.removeItem('SelectedID')

  const formRef = ref(null);
  const formValue = (() => {
    const tempValue = {};
    Object.keys(resReqDataMap).forEach(key => {
      tempValue[key] = resReqDataMap[key].default;
    });
    return reactive(tempValue);
  })();
  const formRules = (() => {
    const tempRules = {};
    Object.keys(resReqDataMap).forEach(key => {
      const rule = resReqDataMap[key].rule;
      rule && (tempRules[key] = rule);
    });
    return tempRules;
  })();
  const triggerLabelValidator = () => {
    formRef.value.validate(
      errors => {},
      rule => rule.key === 'label_ids'
    );
  };

  const itemAddLabelRef = ref(null);
  const handleLabelChange = selectedLabelsArr => {
    formValue.label_ids = selectedLabelsArr.map(item => item.id);
    triggerLabelValidator();
  };

  const randomVolume = ref(null)
  // 随机组卷
  const randomAdd = () => {
    randomVolume.value.openModalRandom({
      initIds: formValue.paper_exercises
    });
  };

  const batAddBtnOptions = [
    { label: '从习题库复制', key: 'fromExercises' },
    { label: '本地上传', key: 'localUpdate' },
    { label: '随机组卷', key: 'randomVolume' }
  ];
  const handleClickBatAdd = key => {
    switch (key) {
      case 'fromExercises':
        handleCopyFromExercises();
        break;
      case 'localUpdate':
        batImportRef.value.openModal();
        break;
      case 'randomVolume':
        randomAdd();
        break;
    }
  };

  const isTestPaperCall = ref(true)   // 是否试卷调用
  const addExercisesModalRef = ref(null);
  const handleCopyFromExercises = () => {
    addExercisesModalRef.value.openModal();
  };
  const handleAfterAdd = index => {
    dstIndex = index + 1;
    isReplace = false;
    exerciseEditing.value = false;
    manualAdding.value = true;
    nextTick(() => {
      addExerciseRef.value.resetFormValue();
    });
  };

  const allArrIDS = ref([])
  const exerciseIdAll = ids =>{
    for(var i=0;i>ids.length;i++){
      if(allArrIDS.value.indexOf(ids[i])){
      }else{
        allArrIDS.value.push(ids[i])
      }
    }
  }

  
  const handleAddExerciseIds = ids => {
    const addIds = ids;
    if (addIds.length > 0) {
      loading.value = true;
      getExercises({
        'Paper[exercises_ids]': addIds.join(',')
      }).then(res => {
        if (res.code === SUCCESS) {
          if (Array.isArray(res.data)) {
            addExercises({
              where: 'last',
              exercises: res.data.map(item => {
                const tempExercise = {};
                exerciseObjKeys.forEach(key => {
                  const type = Number(item.type);
                  switch (key) {
                    case 'ques_select_num':
                      switch (type) {
                        case questionTypeEnum.OPEN:
                        case questionTypeEnum.FILL_BLANK:
                          tempExercise[key] = 0;
                          break;
                        default:
                          tempExercise[key] = item.ques_select.length;
                      }
                      break;
                    case 'ques_select':
                      switch (type) {
                        case questionTypeEnum.OPEN:
                          tempExercise[key] = [];
                          break;
                        case questionTypeEnum.FILL_BLANK:
                          tempExercise[key] = item.ques_answer;
                          break;
                        default:
                          tempExercise[key] = item.ques_select;
                      }
                      break;
                    case 'Exercises[upload]':
                      tempExercise['upload_list'] = item['Exercises[upload]']
                      break;
                    default:
                      tempExercise[key] = item[key];
                  }
                });
                return tempExercise;
              })
            });
          }
        }
      }).catch(err => {}).finally(() => {
        loading.value = false;
      });
    }
  };

  const batImportRef = ref(null);

  const addExerciseRef = ref(false);
  const manualAdding = ref(false);
  const exerciseEditing = ref(false);
  const exerciseResReqParamsMap = {
    id: 'id', // 复制的习题的 id
    title: 'title', // String
    type: 'type', // String
    ques_select: 'ques_select', // Array
    ques_select_num: 'ques_select_num', // Number
    ques_answer: 'ques_answer', // String
    ques_analys: 'ques_analys', // String
    score: 'score', // Number
    upload_list: 'upload_list'
  };
  let dstIndex;
  let isReplace = false;
  const handleClickManualAdd = () => {
    dstIndex = formValue.paper_exercises.length;
    isReplace = false;
    exerciseEditing.value = false;
    manualAdding.value = true;
    nextTick(() => {
      addExerciseRef.value.resetFormValue();
    });
  };
  const handleCopyExercise = ({ index, exercise }) => {
    addExercises({
      where: 'middle',
      isReplace: false,
      dstIndex: formValue.paper_exercises.length,
      exercises: [JSON.parse(JSON.stringify(exercise))]
    });
  };
  const handleEditExercise = ({ index, exercise }) => {
    dstIndex = index;
    isReplace = true;
    manualAdding.value = true;
    exerciseEditing.value = true;
    nextTick(() => {
      addExerciseRef.value.initFormValue(exercise);
    });
  };
  const handleCancelExercise = () => {
    manualAdding.value = false;
  };
  const handleConfirmExercise = exercise => {
    if(exercise['Exercises[upload]']!=undefined){
      exercise['upload_list'] = exercise['Exercises[upload]']
    }
    addExercises({
      where: 'middle',
      isReplace,
      dstIndex,
      exercises: [exercise]
    });
    handleCancelExercise();
  };

  const addExercises = ({ where, isReplace, dstIndex, exercises }) => {
    switch (where) {
      case 'last':
        if (Array.isArray(exercises)) {
          formValue.paper_exercises.push(...exercises);
        }
        break;
      case 'middle':
        formValue.paper_exercises.splice(dstIndex, Number(isReplace), ...exercises);
        break;
    }
  };
  const handleExerciseDel = index => {
    let id = toRaw(formValue.paper_exercises)[index].id
    allArrIDS.value.forEach((item, index1) => {
      if(id==item){
        allArrIDS.value.remove(index1,allArrIDS.value);
      }
    });
    formValue.paper_exercises.splice(index, 1);
  };

      // 数组删除下标
  Array.prototype.remove = function (dx, arr) {
    if (isNaN(dx) || dx > arr.length) {
      return false;
    }
    for (var i = 0, n = 0; i < arr.length; i++) {
      if (arr[i] != arr[dx]) {
        arr[n++] = arr[i];
      }
    }
    arr.length -= 1;
  };

  const handleSeqPre = index => {
    if (index > 0) {
      const item = formValue.paper_exercises.splice(index, 1)[0];
      formValue.paper_exercises.splice(index - 1, 0, item);
    }
  };
  const handleSeqNext = index => {
    if (index < formValue.paper_exercises.length - 1) {
      const item = formValue.paper_exercises.splice(index, 1)[0];
      formValue.paper_exercises.splice(index + 1, 0, item);
    }
  };
  const handleSeqTop = index => {
    if (index > 0) {
      const item = formValue.paper_exercises.splice(index, 1)[0];
      formValue.paper_exercises.unshift(item);
    }
  };
  const handleSeqBottom = index => {
    if (index < formValue.paper_exercises.length - 1) {
      const item = formValue.paper_exercises.splice(index, 1)[0];
      formValue.paper_exercises.push(item);
    }
  };

  const handleBack = () => {
    router.push('list');
  };
  const scrollbarRef = ref(null);
  const handleDone = () => {
    formRef.value.validate(errors => {
      if (!errors) {
        if (formValue.paper_exercises.length > 0) {
          const reqData = {};
          Object.keys(resReqDataMap).forEach(key => {
            switch (key) {
              case 'paper_exercises':
                const exercises = formValue.paper_exercises.map(item => {
                  const exercise = { ...item };
                  exercise.ques_select_num = 0;
                  switch (Number(item.type)) {
                    case questionTypeEnum.OPEN:
                      delete exercise.ques_answer;
                      delete exercise.ques_select;
                      break;
                    case questionTypeEnum.FILL_BLANK:
                      delete exercise.ques_select;
                      break;
                    default:
                      exercise.ques_select_num = exercise.ques_select.length;
                  }
                  if (exercise.id) {
                    exercise.exercises_id = exercise.id;
                    delete exercise.upload_list;
                  }
                  delete exercise['Exercises[upload]'];
                  delete exercise.id;
                  delete exercise['Exercises[type_status]'];
                  if (exercise.upload_list) {
                    exercise.upload_list = exercise.upload_list.map(uploadItem => ({
                      file_url: uploadItem.file_url,
                      file_name: uploadItem.file_name,
                      file_size: uploadItem.file_size,
                      file_type: uploadItem.file_type,
                      extension: uploadItem.extension
                    }));
                  }
                  return exercise;
                });
                reqData[resReqDataMap[key].reqKey] = exercises;
                break;
              default:
                reqData[resReqDataMap[key].reqKey] = formValue[key];
            }
          });

          let reqFn;
          let successMsg = '';
          if (isEdit) {
            reqFn = editPaperFromExercises;
            successMsg = '试卷编辑成功';
          } else {
            reqFn = createPaperFromExercises;
            successMsg = '试卷添加成功';
          }
          loading.value = true;
          reqFn(reqData).then(res => {
            if (res.code === SUCCESS) {
              message.success(successMsg);
              router.push('list');
            }
          }).catch(err => {}).finally(() => {
            loading.value = false;
          });
        } else {
          message.error('请添加习题');
        }
      } else {
        scrollbarRef.value.scrollTo({
          top: 0
        });
      }
    });
  };

  const totalScore = computed(() => {
    let total = 0;
    formValue.paper_exercises.forEach(item => {
      total += parseFloat(item.score) || 0;
    });
    return (Math.round(total * 100) / 100).toFixed(1);
  });

  if (isEdit) {
    loading.value = true;
    getPaperDetail({
      'Paper[id]': paperId
    }).then(res => {
      if (res.code === SUCCESS) {
        const resData = res.data;
        Object.keys(resReqDataMap).forEach(key => {
          switch (key) {
            case 'id':
              break;
            case 'type':
              break;
            case 'label_ids':
              const labels = resData.label_list.map(item => ({
                id: item.label_id,
                title: item.title,
                file_num: item.file_num
              }));
              formValue.label_ids = labels.map(item => item.id);
              itemAddLabelRef.value.setSelectedLabels(labels);
              break;
            case 'paper_exercises':
              formValue[key] = resData.paper_exercises.map(item => {
                
                let {
                  title,
                  type,
                  score,
                  ques_select,
                  ques_answer,
                  ques_analys,
                  upload_list,
                  id,
                  exercises_id // 复制的原习题 id
                } = item;
                let ques_select_num = 0;
                const numberType = Number(type);
                switch (numberType) {
                  case questionTypeEnum.OPEN:
                    break;
                  case questionTypeEnum.FILL_BLANK:
                    break;
                  default:
                    upload_list = item.upload_list
                    ques_select_num = ques_select.length;
                }
                return {
                  title,
                  type,
                  score,
                  ques_select,
                  ques_select_num,
                  ques_answer,
                  ques_analys,
                  upload_list,
                  id: exercises_id === '0' ? '' : exercises_id
                };
              });
              break;
            case 'deleteExerciseIds':
              formValue[key] = resData.paper_exercises.map(item => item.id);
              break;
            default:
              formValue[key] = resData[key];
          }
        });
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  }
</script>